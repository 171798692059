require('./src/styles/normalize.css')
require('./src/styles/normalize-reference.css')
require('./src/styles/webflow.css')
require('./src/styles/webflow-reference.css')
require('./src/styles/oscehub.webflow.css')
require('./src/styles/latest-webflow.css')
require('./src/styles/oscehub.webflow-reference.css')

const React = require('react');
const Layout = require('./src/layout/main').default

exports.wrapPageElement = ({element, props}) => {
  return <Layout {...props}>{element}</Layout>
}