import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navbar from "../components/Navbar";
import "../styles/index.css";
import styled from "styled-components";
import { Children } from "react";
import SEO from "../components/seo";
import Advert from "../components/Advert";
import { ToastProvider } from "react-toast-notifications";
import { FirebaseContext, useAuth } from "../components/Firebase";
import TopBanner from "../components/Banner";
import Footer from "../components/Footer";
import Rewardful from "../components/Rewardful";
import Twemoji from "react-twemoji";

const Title = styled.h1`
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: calc(30px + 3vw);
  text-align: center;
  color: white;
  line-height: 1;
  margin: 5px;
`;

const Banner = styled.div`
  position: relative;
  top: 55px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  background-size: 400% 400%;
  animation: gradient 100s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ChecklistWrapper = styled.div`
  top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ChildContainer = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
`;

const Layout = ({ children }) => {
  var darkMode = false;
  if (children.props.data != null) {
    if (children.props.data.strapiStations != null) {
      darkMode = true;
    }
  }
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const { user, firebase, loading, serverTime } = useAuth();
  const [betaLeft, setBetaLeft] = useState(0);
  var count = 0;
  useEffect(() => {
    if (!loading) {
      if (firebase) {
        firebase.db
          .collection("limited_stock_items")
          .doc("beta")
          .onSnapshot((doc) => setBetaLeft(doc.data().amount));
      }
      if (user) {
        firebase.db
          .collection("users")
          .doc(user.uid)
          .collection("logins")
          .doc("last login")
          .onSnapshot((doc) => {
            // //console.log(doc.data())
            count += 1;
            //console.log(count);
            if (count == 2) {
              //console.log("sign out");
              firebase
                .logout()
                .then(() => (window.location.href = "/loggedOut"));
            }
          });
      }
    }
  }, [loading]);

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading, serverTime }}>
      <Twemoji options={{ className: "emoji" }}>
        <ToastProvider>
          <Rewardful />
          <div class="body-2">
            <TopBanner darkMode={darkMode} betaLeft={betaLeft} />
            <Navbar darkMode={darkMode} />
            {children}
            <Footer />
          </div>
        </ToastProvider>
      </Twemoji>
    </FirebaseContext.Provider>
  );
};

export default Layout;
