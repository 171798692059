import React, { useState } from "react";
import styled from "styled-components";
import { StyledButton } from "../Buttons/StyledButton";

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const FormSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 10px 30px 10px;
`;

export default function ContactForm() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
    success: false,
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => {
        setFormState({
          name: "",
          email: "",
          message: "",
          success: true,
        });
      })
      .catch((error) => alert(error));
    e.preventDefault();
  };

  return (
    <div>
      {formState.success && (
        <div class="alert alert-success" role="alert">
          Your message has been submitted
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <FormContainer>
          <FormSubContainer>
            <div class="form-group">
              <label for="FormControlInput1">Name</label>
              <input
                required
                type="text"
                class="form-control"
                id="FormControlInput1"
                placeholder="John Smith"
                name="name"
                onChange={handleChange}
                value={formState.name}
              />
            </div>
            <div class="form-group">
              <label for="FormControlInput2">Email address</label>
              <input
                required
                type="email"
                class="form-control"
                id="FormControlInput2"
                placeholder="name@example.com"
                name="email"
                onChange={handleChange}
                value={formState.email}
              />
            </div>
            <div class="form-group">
              <label for="FormControlTextarea1">Your Message:</label>
              <textarea
                required
                class="form-control"
                id="FormControlTextarea1"
                rows="10"
                name="message"
                onChange={handleChange}
                value={formState.message}
              ></textarea>
            </div>
            <StyledButton type="submit">Submit</StyledButton>
          </FormSubContainer>
        </FormContainer>
      </form>
    </div>
  );
}
