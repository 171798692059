import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'mob-nav-text'
  },
  {
    title: 'Example',
    path: '/example',
    icon: <FaIcons.FaCartPlus />,
    cName: 'mob-nav-text'
  },
  {
    title: 'Browse',
    path: '/browse',
    icon: <IoIcons.IoMdPeople />,
    cName: 'mob-nav-text'
  },
  {
    title: 'Pricing',
    path: '/#prices',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'mob-nav-text'
  },
  {
    title: 'Free Trial',
    path: '/registration',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'mob-nav-text'
  },
  {
    title: 'Log in',
    path: '/login',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'mob-nav-text'
  }
];

export const SidebarData_Authenticated = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <AiIcons.AiFillHome />,
    cName: 'mob-nav-text'
  },

];