import { navigate, Link } from "gatsby";
import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../Firebase";
import LoadingWall from "../LoadingWall";
import Mobile from "./mobile";

function Navbar({ darkMode }) {
  const { firebase, user, loading } = useContext(FirebaseContext);
  const [naviLoading, setNaviLoading] = useState(false);
  function handleLogoutClick() {
    firebase.logout().then(() => (window.location.href = "/login"));
  }

  return (
    <div
      data-animation="over-right"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class={darkMode ? "navbar dark w-nav" : "navbar w-nav"}
    >
      {naviLoading && <LoadingWall />}
      {!user ? (
        <div class="container w-container">
          <a href="/" aria-current="page" class="brand w-nav-brand w--current">
            <div class={darkMode ? "text-block-3 light" : "text-block-3"}>
              OSCEhub 🩺{" "}
              {/* <span class={darkMode ? "text-span-5 light" : "text-span-5"}>
                BETA
              </span> */}
            </div>
          </a>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <a
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
              onClick={() => {
                setNaviLoading(true);
                navigate("/browse");
                setNaviLoading(false);
              }}
            >
              Browse
            </a>
            <a
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
              onClick={() => {
                setNaviLoading(true);
                navigate("/example");
                setNaviLoading(false);
              }}
            >
              Examples
            </a>
            <a
              href="https://oscehub.noorahq.com/feature-requests"
              target="_blank"
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
            >
              Feedback
            </a>
            <a
              onClick={() => {
                setNaviLoading(true);
                navigate("/#prices");
                setNaviLoading(false);
              }}
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
            >
              Pricing
            </a>
            <a
              onClick={() => {
                setNaviLoading(true);
                navigate("/login");
                setNaviLoading(false);
              }}
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
            >
              Log in
            </a>
            <div
              class={
                darkMode
                  ? "nav-link focused-button w-nav-link light"
                  : "nav-link focused-button w-nav-link"
              }
              onClick={() => {
                setNaviLoading(true);
                navigate("/registration");
                setNaviLoading(false);
              }}
            >
              Free Trial
            </div>
          </nav>
          <Mobile darkMode={darkMode} />
        </div>
      ) : (
        <div class="container w-container">
          <a
            onClick={() => navigate("/")}
            aria-current="page"
            class="brand w-nav-brand w--current"
          >
            <div class={darkMode ? "text-block-3 light" : "text-block-3"}>
              OSCEhub 🩺{" "}
              {/* <span class={darkMode ? "text-span-5 light" : "text-span-5"}>
                BETA
              </span> */}
            </div>
          </a>
          <nav role="navigation" class="nav-menu w-nav-menu">
            {user && (
              <div
                class={
                  darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
                }
              >
                {`ID: ${user.shortId}`}
              </div>
            )}
            <a
              onClick={() => navigate("/dashboard")}
              target="_blank"
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
            >
              Dashboard
            </a>
            <a
              href="https://oscehub.noorahq.com/feature-requests"
              target="_blank"
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
            >
              Feedback
            </a>
            <a
              class={
                darkMode ? "nav-link light w-nav-link" : "nav-link w-nav-link"
              }
              onClick={handleLogoutClick}
            >
              Log out
            </a>
            <a
              href="/#prices"
              class={
                darkMode ? "nav-link focused-button light w-nav-link"
                  : "nav-link focused-button w-nav-link purple-box"
              }
            >
              Subscribe
            </a>
            
          </nav>
          <Mobile darkMode={darkMode} />
        </div>
      )}
    </div>
  );
}

export default Navbar;
