import styled from "styled-components";
import React from "react";
import { CircularProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const LoadingWallContainer = styled.div`
  position: fixed;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: #ecebff;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e173f",
    },
  },
});

function LoadingWall() {
  return (
    <ThemeProvider theme={theme}>
      <LoadingWallContainer>
        <CircularProgress color="primary" size={60} />
        <div class="just-a-moment">Just a moment ...</div>
      </LoadingWallContainer>
    </ThemeProvider>
  );
}

export default LoadingWall;
