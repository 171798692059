import { useEffect, useState } from "react";
import getFirebaseInstance from "./firebase";
import loadFirebaseDependencies from "./loadFirebaseDependencies";

function useAuth() {
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [serverTime, setServerTime] = useState(null);
  useEffect(() => {}, [user]);
  useEffect(() => {
    let unsubscribe;

    loadFirebaseDependencies.then((app) => {
      const firebaseInstance = getFirebaseInstance(app);
      setFirebase(firebaseInstance);
      firebaseInstance.getServerTime().then((result) => {
        setServerTime(result.data.time);
      });
      unsubscribe = firebaseInstance.auth.onIdTokenChanged((userResult) => {
        //console.log("detected token change");
        if (userResult) {
          firebaseInstance.db
            .collection("users")
            .doc(userResult.uid)
            .onSnapshot((doc) => {
              setUser({
                ...userResult._delegate,
                email: doc.data().email,
                firstname: doc.data().firstname,
                lastname: doc.data().lastname,
                expiryTime: doc.data().expiryTime,
                latestPlan: doc.data().latestPlan,
                stripeId: doc.data().stripeId,
                referralLink: doc.data().rewardfulLink,
                referredBy:
                  (doc.data().referred_by &&
                    doc.data().referred_by.referrerName) ||
                  doc.data().referred_by,
                shortId: doc.data().shortId,
                university: doc.data().university
              });
              setLoading(false);
            });
        } else {
          setUser(null);
          setLoading(false);
        }
      });
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return { user, firebase, loading, serverTime };
}

export default useAuth;
