import React from 'react'
import styled from 'styled-components'
import { StyledButton } from '../Buttons/StyledButton'
import { StyledButtonModal } from '../Modals/advertModal'

const Header = styled.h2 `
  /* text-align: center; */
`

const Text = styled.p `
  /* text-align: center; */
`

const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Container = styled.div `
  background-color: #d1f7fb;
  padding: 20px;
`


function Advert() {
  return (
    <Container>
      <Wrapper>
        <Header>
        👋 We're recruiting writers! 
        </Header>
        <Text>
          🚀 Help us shape the future of medical education 
        </Text>
        <Text>
          🎖 We give certificates as well as the option of adding your name to every station you write
        </Text>
        <StyledButtonModal />
      </Wrapper>
      
    </Container>
  )
}

export default Advert
