import React, {useEffect} from "react";
import { Helmet } from "react-helmet";

function Rewardful() {
  const isBrowser = () => typeof window !== "undefined";
  useEffect(() => {
    (function (w, r) {
      w._rwq = r;
      w[r] =
        w[r] ||
        function () {
          (w[r].q = w[r].q || []).push(arguments);
        };
    })(window, "rewardful");
  }, []);
  return (
    <Helmet>
      <script
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful="fd9b94"
      ></script>
    </Helmet>
  );
}

export default Rewardful;
