import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { navigate } from "gatsby-link";
import initializeStripe from "../Stripe/initialiseStripe";
import app from "./app";

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.app().functions("europe-west2");
      if (process.env.GATSBY_ENVIRONMENT == "development") {
        this.functions.useEmulator("localhost", 5001);
      }

      this.storage = app.storage();
    }
  }

  async createCheckoutSession({ uid }) {
    const checkoutSessionRef = await this.db
      .collection("users")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: "price_1K5Wo8FYlaVPK4IAT4asWCrf",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

    checkoutSessionRef.onSnapshot(async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        const stripe = await initializeStripe();
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }

  async submitStation({
    stationId,
    tickedPoints,
    percentage,
    breakdown,
    stationTitle,
    stationType,
    specialty,
  }) {
    const functionRef = this.functions.httpsCallable("submitStation");
    const response = await functionRef({
      stationId,
      tickedPoints,
      percentage,
      breakdown,
      stationTitle,
      stationType,
      specialty,
    });
    return response;
  }

  async submitPeerStation({
    shortId,
    stationId,
    tickedPoints,
    percentage,
    breakdown,
    email,
    stationTitle,
    stationType,
    specialty,
  }) {
    const functionRef = this.functions.httpsCallable("submitPeerStation");
    const response = await functionRef({
      shortId,
      stationId,
      tickedPoints,
      percentage,
      breakdown,
      email,
      stationTitle,
      stationType,
      specialty,
    });
    return response;
  }

  async retrieveStationsData() {
    const functionRef = this.functions.httpsCallable("retrieveStationsData");
    let stations;
    await functionRef().then((response) => {
      stations = response;
    });
    return stations;
  }

  async retrieveStationAttempts({ stationId }) {
    const functionRef = this.functions.httpsCallable("retrieveStationAttempts");
    let stations;
    await functionRef({ stationId }).then((response) => {
      stations = response;
    });
    return stations;
  }

  async logPage({ page_title }) {
    console.log("logging page");
    const functionRef = this.functions.httpsCallable("logPageOpened");
    await functionRef({
      page_title,
    });
  }

  async customerPortalRedirect() {
    var functions = app.app().functions("europe-west2");
    const functionRef = functions.httpsCallable(
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin,
    });
    window.location.assign(data.url);
  }

  async isUserPremium() {
    await this.auth.currentUser.getIdToken(true);
    const decodedToken = await this.auth.currentUser.getIdTokenResult();

    return decodedToken.claims.stripeRole ? true : false;
  }

  async updateProfile({ firstname, lastname, userId }) {
    const users = this.db.collection("users");
    await users.doc(userId).set(
      {
        firstname: firstname,
        lastname: lastname,
      },
      { merge: true }
    );
    window.location.reload();

    return;
  }

  async getServerTime() {
    const time = await this.functions.httpsCallable("getServerTime");
    return time();
  }

  async processReferral({ referrerId, referrerName }) {
    const rewardfulProcess = this.functions.httpsCallable("processReferral");
    await rewardfulProcess({
      referrerId,
      referrerName,
    });
  }

  async getNumberOfMonthsReferred({ user }) {
    let size;
    const userProfile = await this.db
      .collection("users")
      .doc(user.uid)
      .collection("referrals")
      .get()
      .then((snap) => {
        size = snap.size;
      });
    return size;
  }

  async register({
    email,
    password,
    firstname,
    lastname,
    university,
    mailingList,
  }) {
    const createProfileCallable =
      this.functions.httpsCallable("createUserProfile");
    await this.auth.createUserWithEmailAndPassword(email, password).then(() =>
      createProfileCallable({
        email,
        firstname,
        lastname,
        university,
        mailingList,
      })
    );
    await this.auth.currentUser.sendEmailVerification();
  }

  async login({ email, password }) {
    const logSignInTime = await this.functions.httpsCallable("logSignInTime");
    const userCredential = await this.auth.signInWithEmailAndPassword(
      email,
      password
    );
    logSignInTime();
  }

  async logout() {
    await this.auth.signOut();
  }
}

let firebaseInstance;

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase();
    return firebaseInstance;
  } else if (firebaseInstance) {
    return firebaseInstance;
  } else {
    return null;
  }
}

export default getFirebaseInstance;
