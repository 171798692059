import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import "../../styles/mobileNav.css";
import { SidebarData, SidebarData_Authenticated } from "./mobileBarData";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { FirebaseContext } from "../Firebase";

const ShortIDDisplay = styled.h2`
  color: white;
  font-size: 15px;
  font-weight: 400;
`;

function Mobile({ darkMode }) {
  const { user, firebase } = useContext(FirebaseContext);
  function handleLogoutClick() {
    firebase.logout().then(() => (window.location.href = "/login"));
  }
  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  //console.log(user);
  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <div onClick={toggleSidebar} class="menu-button w-nav-button">
        <div
          class={
            darkMode ? "icon-3 light w-icon-nav-menu" : "icon-3 w-icon-nav-menu"
          }
        ></div>
      </div>
      <nav className={sidebar ? "mob-nav-menu active" : "mob-nav-menu"}>
        <ul className="mob-nav-menu-items" onClick={toggleSidebar}>
          <li className="mob-navbar-toggle">
            {user && <ShortIDDisplay>{`ID: ${user.shortId}`}</ShortIDDisplay>}
            <Link to="#" className="mob-menu-bars">
              <AiIcons.AiOutlineClose />
            </Link>
          </li>
          <li class="mob-nav-text">
            <a href="https://oscehub.noorahq.com/feature-requests">
              <span>Feedback</span>
            </a>
          </li>
          {user ? (
            <>
              {SidebarData_Authenticated.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {/* {item.icon} */}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
              <li class="mob-nav-text">
                <a onClick={handleLogoutClick}>
                  <span>Log out</span>
                </a>
              </li>
            </>
          ) : (
            <>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {/* {item.icon} */}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </>
          )}
        </ul>
      </nav>
    </IconContext.Provider>
  );
}

export default Mobile;
