import { Link, navigate } from "gatsby";
import React from "react";

function index() {
  return (
    <footer id="footer" class="footer wf-section">
      <div class="w-container">
        <div class="footer-flex-container">
          <a onClick={() => navigate("/")} class="link-block w-inline-block">
            <div class="oscehub-footer">OSCEhub</div>
            <div class="footer-text">
              OSCE Practice for <b>1st Decile Students</b>
              <br></br>
              <br></br>
            <u>Before OSCEhub:</u> Hectic OSCE practise, stations aren't challenging, and no idea if you're improving. 
            <br></br>
            <br></br>
            <u>After OSCEhub:</u> Always ready to practise, productive & challenging sessions, clear on where to improve
            </div>
          </a>
          <div>
            <h2 class="footer-heading">OSCEhub</h2>
            <ul role="list" class="w-list-unstyled">
              <li>
                <a onClick={() => navigate("/")} class="footer-link">
                  Home
                </a>
              </li>
              <li>
                <Link to="/#prices" class="footer-link">
                  Pricing
                </Link>
              </li>
              <li>
                <a onClick={() => navigate("/example")} class="footer-link">
                  Example Station
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/browse")} class="footer-link">
                  Browse
                </a>
              </li>
              <li>
                <a href="https://oscehub.noorahq.com/feature-requests" class="footer-link">
                  Feedback
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="footer-heading">Account</h2>
            <ul role="list" class="w-list-unstyled">
              <li>
                <a onClick={() => navigate("/login")} class="footer-link">
                  Log in
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/registration")} class="footer-link">
                  Register
                </a>
                <a onClick={() => navigate("/registration")} class="footer-link">
                  Free Trial
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="footer-heading">Contact</h2>
            <ul role="list" class="w-list-unstyled">
              <li>
                <a href="mailto:hello@oscehub.co.uk" class="footer-link">
                  Email
                </a>
              </li>
              <li>
                <a href="https://twitter.com/oscehub" class="footer-link">
                  Twitter
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-block-2">
          Copyright © 2021 OSCEhub Limited. All rights reserved. Made with ❤️.
        </div>
      </div>
    </footer>
  );
}

export default index;
