import React from "react";
import { Link } from "gatsby";

function Banner({ darkMode }) {
  return (
    <Link to="/#prices">
      <div class={darkMode ? "banner light" : "banner"}>
        <div class={darkMode ? "banner-text dark" : "banner-text"}>
          {/* {`NEW: accurate scoring system, target weaknesses & become a diagnostic expert ✨ `} */}
          {`Don't Miss Out! 15% Off OSCE Station Prep – Use Code OSCEHUB15 for a Limited Time 📖`}
        </div>
      </div>
    </Link>
  );
}

export default Banner;
