import styled from 'styled-components'

export const StyledButton = styled.button `
  text-decoration: none;
  color: white;
  background-color: #5c73d0;
  border-radius: 30px;
  padding: 10px;
  border: none;
  width: fit-content;

  &:hover {
    color: white;
    background-color: #6c83e0;
    cursor: pointer;
  }
`