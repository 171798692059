import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51K5GBrFYlaVPK4IAwi5jQNt4QkeuRNCmKqBHFw0ZzwfqsqlOqWowj9stUSzrad9lfPMfhorO0eKk5FWGJn3spPAo00cZlNDG5z"
    );
    // stripePromise = await loadStripe(
    //   "pk_test_51K5GBrFYlaVPK4IAMGVleIQyzwKQ48kgQl6NljGb3UeBhoR2EBwEpgzqLzyhFB1lSDiYZ85Y9nn8RZmiP3io7PRc00DPy8ObwY"
    // );
  }
  return stripePromise;
};

export default initializeStripe;
